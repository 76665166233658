import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Image from '../Image'
import { DARKER, LIGHTEST, NEUTRAL } from '../../constants/colors'

const RADIUS = '10px'

const Container = styled.div`
  margin: 0 auto;
  margin-bottom: 1.5rem;
  border-radius: ${RADIUS};
  border: solid 1px ${NEUTRAL};
  box-shadow: 2px 2px 10px ${NEUTRAL};
  background-color: ${LIGHTEST}aa;
`

const Heading = styled.h2``

const Body = styled.div`
  padding: 2rem;
  display: ${(props) => (props.show ? 'block' : 'none')};

  .image {
    box-shadow: 2px 2px 10px ${DARKER};
    border-radius: 2rem;
    width: 100%;
    max-width: 400px;
    max-height: 300px;
    float: left;
    margin-right: 2rem;
    margin-bottom: 1.5rem;
  }
`

const Component = ({ image, toggled, title, children }) => {
  const [show, toggle] = useState(toggled)
  return (
    <Container className="panel">
      <Heading
        onClick={() => toggle(!show)}
        className={`heading ${show ? 'active' : ''}`}
      >
        {title}
        <div className={`chevron ${show ? 'open' : 'closed'}`}></div>
      </Heading>
      <Body show={show}>
        {image && <Image className="image" filename={image} alt={title} />}
        {children}
      </Body>
    </Container>
  )
}

Component.defaultProps = {
  image: undefined,
  toggled: () => {},
}

Component.propTypes = {
  children: PropTypes.node.isRequired,
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  toggled: PropTypes.func,
}

export default Component
