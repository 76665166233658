import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

const Image = (props) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(
          filter: { extension: { regex: "/jpeg|jpg|png|gif/" } }
        ) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(maxWidth: 1440, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const image = ((data && data.images && data.images.edges) || []).find(
        (img) => img.node.relativePath === props.filename,
      )

      return image ? (
        <Img
          {...props}
          backgroundColor={props.backgroundColor || '#eeeeee'}
          fluid={image.node.childImageSharp.fluid}
          title={props.alt}
          alt={props.alt}
          fadeIn={true}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )
    }}
  />
)

Image.defaultProps = {
  backgroundColor: 'transparent',
}

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  filename: PropTypes.string.isRequired,
}

export default Image
