import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { LIGHTEST, PRIMARY, SECONDARY, TERTIARY } from '../../constants/colors'

const CHEVRON = 10
const BORDER = 10
const RADIUS = 8

const heading = (color) => `
  .heading {
    border-color: ${color};
    background: linear-gradient(to right, ${color} 50%, transparent 0px)
      right bottom / 200% 100%;

    &:hover {
      background: linear-gradient(to right, ${color} 50%, transparent 0px)
        left bottom / 200% 100%;
    }
  }

  .heading.active {
    background-color: ${color};
  }

  .chevron.closed {
    border-top: ${CHEVRON}px solid ${color};
  }
`

const Accordion = styled.div`
  position: relative;
  padding-bottom: 0.5rem;
  z-index: 1;

  .chevron {
    width: 0;
    height: 0;
    margin-top: 6px;
    border-left: ${CHEVRON}px solid transparent;
    border-right: ${CHEVRON}px solid transparent;

    &.open {
      border-bottom: ${CHEVRON}px solid ${LIGHTEST};
    }
  }

  .heading {
    border-radius: ${RADIUS}px;
    padding: 1rem;
    padding-left: 1.25rem;
    border-left: ${BORDER}px solid transparent;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    transition: background 0.5s ease-out 0s;
    font-size: 1.1rem !important;
    font-weight: normal !important;

    &:hover {
      color: ${LIGHTEST};

      .chevron.closed {
        border-top: ${CHEVRON}px solid ${LIGHTEST};
      }
    }

    &.active {
      border-radius: ${RADIUS}px ${RADIUS}px 0 0;
      color: ${LIGHTEST};
    }
  }
  .panel:nth-child(3n + 1) {
    ${heading(TERTIARY)}
  }

  .panel:nth-child(3n + 2) {
    ${heading(SECONDARY)}
  }

  .panel:nth-child(3n + 3) {
    ${heading(PRIMARY)}
  }
`

const Component = ({ children }) => <Accordion>{children}</Accordion>

Component.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Component
